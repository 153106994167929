// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogue-js": () => import("./../../../src/pages/catalogue.js" /* webpackChunkName: "component---src-pages-catalogue-js" */),
  "component---src-pages-connect-examples-js": () => import("./../../../src/pages/connect/examples.js" /* webpackChunkName: "component---src-pages-connect-examples-js" */),
  "component---src-pages-connect-file-upload-js": () => import("./../../../src/pages/connect/file-upload.js" /* webpackChunkName: "component---src-pages-connect-file-upload-js" */),
  "component---src-pages-connect-index-js": () => import("./../../../src/pages/connect/index.js" /* webpackChunkName: "component---src-pages-connect-index-js" */),
  "component---src-pages-connect-thanks-js": () => import("./../../../src/pages/connect/thanks.js" /* webpackChunkName: "component---src-pages-connect-thanks-js" */),
  "component---src-pages-misc-js": () => import("./../../../src/pages/misc.js" /* webpackChunkName: "component---src-pages-misc-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-catalogue-post-js": () => import("./../../../src/templates/catalogue-post.js" /* webpackChunkName: "component---src-templates-catalogue-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-misc-post-js": () => import("./../../../src/templates/misc-post.js" /* webpackChunkName: "component---src-templates-misc-post-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

